<template>
  <div class="product">
    <div class="xzdh">
      <div class="xzdh_box">
        <p>工作日程</p>
        <p>更懂领导的智能小助手，自动统筹安排所有工作</p>
        <p>日程安排 | 智能通知 | 灵活协同 | 日程联动</p>
        <div class="btn">在线咨询</div>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/gzrc/hxld.png" />
        <div class="hxld_box_content" id="title">
          <img src="@/assets/images/product/gzrc/hxld_icon.png" />
          <p>日程安排</p>
          <p>单位日程、个人日程、会议日程、外出日程、值班日程、请休假日程</p>
          <p>
            <span class="icon"></span>
            <span>单位日程（日常工作、日常会议、定期工作、催办事项）</span>
          </p>
          <p>
            <span class="icon"></span>
            <span>个人日程（日常工作、定期工作、记事本）</span>
          </p>
        </div>
      </div>
    </div>
    <div class="zntz">
      <div class="zntz_box_item">
        <div class="zntz_box_content" id="title2">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/gzrc/zntz_icon1.png"
            />
            <p class="first_line">智能通知</p>
            <p class="second_line">
              系统每天短信自动通知人员次日日程与工作事项，<br />可选择是否需要通知，可根据所勾选的事项进行智能提醒
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/gzrc/zntz_icon2.png"
            />
            <p class="first_line">灵活协同</p>
            <p class="second_line">办公室查看领导工作日程，便于统筹相关工作</p>
          </div>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/gzrc/zntz.png"
        />
      </div>
    </div>
    <div class="qxkk">
      <div class="qxkk_box">
        <img
          class="img"
          id="img3"
          src="@/assets/images/product/gzrc/qxkk.png"
        />
        <div class="qxkk_box_content" id="title3">
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/gzrc/qxkk_icon1.png"
            />
            <p class="first_line">权限可控</p>
            <p class="second_line">
              可根据实际需求，将领导日程查看权限开放给特定工作人员
            </p>
          </div>
          <div class="title">
            <img
              class="icon"
              src="@/assets/images/product/gzrc/qxkk_icon2.png"
            />
            <p class="first_line">日程联动</p>
            <p class="second_line">工作日程与智能办会，请休假联动管理</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var zntzH = $('.zntz').offset().top
      var qxkkH = $('.qxkk').offset().top
      if (h > zntzH - 500) {
        $('#img2').addClass('animation')
      }
      if (h > qxkkH - 500) {
        $('#img3').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
